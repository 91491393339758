/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { FormInstance, Modal, Upload, message } from 'antd';
import type { UploadFile, UploadProps } from 'antd';

import { BASE_API_URL } from '../../env';

const getBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

interface ICustomUploadImage {
    name: string;
    fieldValue?: any;
    fieldType: {
        accept?: string;
        maxCount?: number;
        allowImageCropping?: boolean;
        maxFileSize?: number;
        aspectRatio?: number;
    };
    shape?: 'rect' | 'round';
    form: FormInstance;
    nestedKey?: string;
    index?: any;
    placeholder?: string;
}

const CustomUploadDocument = (props: ICustomUploadImage): any => {
    const { name, form, fieldValue, nestedKey, index, fieldType, placeholder } = props;

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<any>([]);

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const setUploadedValueInForm = (value: any) => {
        if (nestedKey) {
            const formFieldValue = form.getFieldValue(name);

            const newFormFieldValue = formFieldValue.map((obj: any, idx: any) => {
                if (idx === index) {
                    return { ...obj, [nestedKey]: value[0] };
                }

                return obj;
            });

            value.length === 1 && nestedKey && form.setFieldsValue({ [name]: newFormFieldValue });
        } else {
            if (value.length === 0) {
                form.setFieldsValue({ [name]: null });
            }

            if (value.length === 1) {
                form.setFieldsValue({ [name]: value[0] });
            }

            if (value.length > 1) {
                form.setFieldsValue({ [name]: value });
            }
        }
    };

    const onChange = (info: any) => {
        const { fileList, file } = info;
        const { status, size } = file;

        switch (status) {
            case 'uploading': {
                const fileSizeCheck = (size: number, allowedSize?: number) => {
                    if (!allowedSize) return true;

                    return size / 1024 < allowedSize;
                };

                const isUploadAllowed = fileSizeCheck(size, fieldType.maxFileSize);

                if (isUploadAllowed) {
                    setFileList(fileList);
                }
                break;
            }

            case 'removed': {
                setFileList(fileList);
                break;
            }

            case 'done': {
                setFileList(fileList);
                message.success(`${info.file.name} file uploaded successfully.`);
                const value = fileList.map((value: any) => (value.url ? value.url : value.response.data));
                setUploadedValueInForm(value);
                break;
            }

            case 'error': {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none', width: '400px' }} type='button'>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload media</div>
        </button>
    );

    useEffect(() => {
        if (fieldValue) {
            const getFileList = (value: any, nestedKey?: string) => {
                if (nestedKey) {
                    // filelist is being set as array even for single url.
                    const fileList = [];
                    const url = value?.[index]?.[nestedKey];

                    if (url)
                        fileList.push({
                            name: '',
                            status: 'done',
                            url,
                        });

                    return fileList;
                }

                return (Array.isArray(value) ? value : [value]).map((url: string) => ({
                    name: '',
                    status: 'done',
                    url,
                }));
            };

            const fileList = getFileList(fieldValue, nestedKey);
            setFileList(fileList);
        }
    }, [fieldValue, index, nestedKey]);

    return (
        <>
            <Upload
                name='documentFile'
                action={`${BASE_API_URL}admin/upload/document`}
                listType='picture-card'
                fileList={fileList}
                onPreview={handlePreview}
                onChange={onChange}>
                {fileList?.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
                style={{ left: '35%', maxHeight: '100vh' }}
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}>
                <img alt='example' style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );
};

export default CustomUploadDocument;
