/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import moment from 'moment';

import useGetResponse from '../../../utils/useGetResponse';
import { InputInterface } from '../../../utils/forms/FormInterface';
import { getIncomingPayload } from '../../../utils/getIncomingPayload';
import { getOutgoingPayload } from '../../../utils/getOutgoingPayload';
import { getAllAccordions } from '../../../utils/forms/formFunctions';
import { formatDate, updateDatamodel, updateFormFields } from '../../../utils/functions';

import { toastError, toastSuccess } from '../../../service/Toast';
import callApi from '../../../Api/config';

import CheckboxGroup from '../../../components/CheckboxGroup';
import TextInput from '../../../components/TextInput';
import Email from '../../../components/Email';
import PinCode from '../../../components/PinCode';
import SelectInput from '../../../components/SelectInput';
import MultiSelectInput from '../../../components/MultiSelectInput';
import NumberInput from '../../../components/NumberInput';
import TimeSlotInput from '../../../components/TimeSlotInput';
import MultipleTextInput from '../../../components/MultipleTextInput';
import MultipleTwinInput from '../../../components/MultipleTwinInput';
import FileInput from '../../../components/FileInput';
import DateInput from '../../../components/DateInput';
import OptionalSelectInput from '../../../components/OptionalSingleSelect';
import TextEditor from '../../../components/TextEditor';
import DrawerInput from '../../../components/DrawerInput';

import { failedMsg } from '../../../constants/messages';
import {
    CATEGORIES_ENTITY,
    USERS_ENTITY,
    ORDER_LIST_ENTITY,
    SUBCATEGORIES_ENTITY,
    DEFECT_TYPES_ENTITY,
    ROLES_ENTITY,
    VARIANTS_ENTITY,
    AVAILABLE_DELIVERY_PARTNERS,
    COMPLAINTS_ENTITY,
    MANAGER_TECHNICIAN_ENTITY,
    ALL_TECHNICIAN_ENTITY,
    ALL_MANAGERS_ENTITY,
    ASSETS_ENTITY,
    LOCATIONS_ENTITY,
    LOCATION_TYPES_ENTITY,
    PHOTO_STORE_PHOTO_SIZE,
    PHOTO_STORE_FILE_TYPE,
    APPLICATION_FORM_SERVICES,
    APPLICATION_FORM_PARAMETERS,
    APPLICATION_FORM_QUESTIONS,
    APPLICATION_FORM_PACKAGE_PLANS,
    APPLICATION_FORM_PACKAGE_FEATURES,
    APPLICATION_FORM_PACKAGE_DOCUMENTS,
    APPLICATION_FORM_PACKAGES,
    APPLICATION_FORM_PACKAGE_SERVICE_ATTRIBUTES,
    SERVICE_PROCESS_STEPS,
    SERVICE_PROCESS_DETAILS,
    SERVICE_PROCESS_SUBTITLE,
    MODELS_ENTITY,
    MAKES_ENTITY,
    CATEGORY_SUBCATEGORIES_ENTITY,
    CATEGORY_CATEGORIES_ENTITY,
    FUEL_TYPE_ENTITY,
    COMMISSION_LIST_ENTITY,
    COMMISSION_TYPE,
    GARAGE_COMMISSION_TYPE,
    REFERRAL_COUPONS_ENTITY,
    USERS,
    SUBCATEGORY,
    CATEGORY,
    PRODUCTS,
    SUBCATEGORY_BY_CATEGORY,
    STORIES,
    PRODUCT_CATEGORIES,
    CATEGORIES,
    SPACES,
    HERO_IMAGE,
    HEADER_NAMES,
    CUSTOMIZABLE_PRODUCTS,
    COLLABS,
    FEATURED_PRODUCTS,
    HEADER_DATA,
    HEADER_INFO,
} from '../../../constants/EntityName';

import '../entity.scss';
import './style.scss';
import TextArea from '../../../components/TextArea';
import DynamicSelectInput from '../../../components/DynamicSelectInput';
import TableInput from '../../../components/TableInput';
import UserRolePermission from '../../../components/UserRolePermission';
import MultipleImageUpload from '../../../components/MultipleImageUpload';
import MultiSpatialImageUpload from '../../../components/MultiSpatialImageUpload';
import GalleryImageUpload, { payloadUrlName } from '../../../components/GalleryImageUpload';
import DocumentFileInput from '../../../components/DocumentFileInput';

export enum UpdateRequestType {
    Put = 'PUT',
    Patch = 'PATCH',
    Post = 'POST',
}

interface AbstractFormProps {
    headerName: string;
    entityName: string;
    nestedEntityName?: string;
    formTemplate: Array<InputInterface>;
    payloadProcessor?: (arg: any, arg2: any, arg3?: any) => any;
    additionalEntityName?: string | { name: string; getAllResponse?: boolean }[];
    entityId?: string;
    isCreateMode?: boolean;
    disabledButton?: { delete?: boolean; update?: boolean; cancel?: boolean };
    formDefaultPayload?: any;
    onSuccessfulUpdate?: (id?: string) => void;
    formHeader?: string | undefined;
    setDrawerVisible?: (props: boolean) => void;
    showDeleteDraft?: boolean;
    table?: React.ReactNode;
    closeTab?: (arg0: string, agr2: boolean) => void;
    updateRequired?: boolean;
    extraPayloadData?: any;
    updateRequestType?: UpdateRequestType;
    apiEndPoint?: string;
    apiMethod?: string;
    additionalButtons?: any[];
}

const AbstractForm = (props: AbstractFormProps) => {
    const {
        entityName,
        isCreateMode,
        formTemplate: initialFormTemplate,
        updateRequired,
        additionalEntityName: incomingAdditionalEntityName,
        extraPayloadData,
        nestedEntityName,
        updateRequestType = UpdateRequestType.Patch,
        additionalButtons,
    } = props;

    const [defaultFormData] = useState(props.formDefaultPayload);
    const [incomingFormData, setIncomingFormData] = useState<any>();
    const [formTemplate, setFormTemplate] = useState(initialFormTemplate);
    const [additionalEntityName, setAdditionalEntityName] = useState(incomingAdditionalEntityName);
    const additionalData = useGetResponse(additionalEntityName);
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const statusRef = useRef<string>();
    const [form] = Form.useForm();

    /**
     * This useEffect is used to update the formTemplate file which is defined in the datamodels folder. It can be required in cases where the options for certain field are not known apriori and are obtained through API call
     */
    useEffect(() => {
        const updateFormTemplate = () => {
            switch (entityName) {
                case PRODUCT_CATEGORIES: {
                    const categoryList = additionalData?.[CATEGORIES]?.data?.results;

                    const check = categoryList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'categoryName') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: categoryList?.map((item: { id: string; name: string }) => ({
                                                value: item.name,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case SPACES: {
                    const productList = additionalData?.[PRODUCTS]?.data?.results;

                    const check = productList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'products') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: productList?.map((item: { _id: string; name: string }) => ({
                                                value: item._id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case HEADER_INFO: {
                    const headerNameList = additionalData?.[HEADER_NAMES]?.data;

                    const check = headerNameList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'name') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: headerNameList?.map((item: string) => ({
                                                value: item,
                                                name: item
                                                    .replace(/[A-Z]/g, ' $&')
                                                    .trim()
                                                    .replace(/^\w/, (c) => c.toUpperCase()),
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case CUSTOMIZABLE_PRODUCTS: {
                    const productList = additionalData?.[PRODUCTS]?.data?.results;

                    const check = productList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'productId') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: productList?.map((item: { _id: string; name: string }) => ({
                                                value: item._id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case COLLABS: {
                    const featureProductList = additionalData?.[FEATURED_PRODUCTS]?.data?.results;

                    const check = featureProductList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'featuredProducts') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: featureProductList?.map((item: { _id: string; name: string }) => ({
                                                value: item._id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case STORIES: {
                    const featureProductList = additionalData?.[FEATURED_PRODUCTS]?.data?.results;

                    const check = featureProductList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'featuredProducts') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: featureProductList?.map((item: { _id: string; name: string }) => ({
                                                value: item._id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                // case USERS_ENTITY: {
                //     const rolesOptions = additionalData?.[ROLES_ENTITY]?.data?.results;

                //     const check = rolesOptions;

                //     check &&
                //         setFormTemplate((formData: any) =>
                //             formData.map((dataModel: any) => {
                //                 if (dataModel.name === 'role') {
                //                     return {
                //                         ...dataModel,
                //                         fieldType: {
                //                             ...dataModel.fieldType,
                //                             options: rolesOptions.map((item: { id: string; name: string }) => ({
                //                                 value: item.id,
                //                                 name: item.name,
                //                             })),
                //                         },
                //                     };
                //                 }

                //                 return dataModel;
                //             }),
                //         );

                //     break;
                // }
                case CATEGORY: {
                    incomingFormData &&
                        setFormTemplate((formData: any) => {
                            return formData.map((singleEntry: any) => {
                                if (singleEntry.dependentOnSpatialRender) {
                                    return { ...singleEntry, isAccordian: incomingFormData.isSpatialRender };
                                }

                                return singleEntry;
                            });
                        });

                    break;
                }

                case SUBCATEGORY: {
                    const categoryList = additionalData?.[CATEGORY]?.data?.results;

                    const check = categoryList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'parentId') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: categoryList?.map((item: { _id: string; name: string }) => ({
                                                value: item._id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case PRODUCTS: {
                    const categoryList = additionalData?.[CATEGORY]?.data?.results;
                    const subCategoryList = additionalData?.[SUBCATEGORY]?.data?.results;

                    let check = categoryList && subCategoryList;

                    if (!isCreateMode) {
                        check = check && incomingFormData;
                    }

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'category') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: categoryList?.map((item: { id: string; name: string }) => ({
                                                value: item.name,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }
                                if (dataModel.name === 'subCategory') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: subCategoryList?.map((item: { id: string; name: string }) => ({
                                                value: item.name,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                if (dataModel.name === 'galleryImage1') {
                                    return {
                                        ...dataModel,
                                        isAccordian:
                                            incomingFormData?.gallery &&
                                            incomingFormData.gallerySpatialRenderImages.length > 0,
                                    };
                                }

                                if (dataModel.name === 'galleryImage2') {
                                    return {
                                        ...dataModel,
                                        isAccordian:
                                            incomingFormData?.gallery &&
                                            incomingFormData.gallerySpatialRenderImages.length > 1,
                                    };
                                }

                                if (dataModel.name === 'galleryImage3') {
                                    return {
                                        ...dataModel,
                                        isAccordian:
                                            incomingFormData?.gallery &&
                                            incomingFormData.gallerySpatialRenderImages.length > 2,
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case MODELS_ENTITY: {
                    const makesList = additionalData?.[MAKES_ENTITY]?.data?.results;

                    const check = makesList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'make') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: makesList?.map((item: { id: string; title: string }) => ({
                                                value: item.id,
                                                name: item.title,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case CATEGORY_SUBCATEGORIES_ENTITY: {
                    const subCategoriesList = additionalData?.[CATEGORY_CATEGORIES_ENTITY]?.data?.results;

                    const check = subCategoriesList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'category') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: subCategoriesList?.map((item: { id: string; title: string }) => ({
                                                value: item.id,
                                                name: item.title,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case APPLICATION_FORM_PACKAGE_PLANS: {
                    const featuresList = additionalData?.[APPLICATION_FORM_PACKAGE_FEATURES]?.data;
                    const documentsList = additionalData?.[APPLICATION_FORM_PACKAGE_DOCUMENTS]?.data;

                    const check = documentsList && featuresList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'documents') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: documentsList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }
                                if (dataModel.name === 'features') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: featuresList?.map((item: { id: string; description: string }) => ({
                                                value: item.id,
                                                name: item.description,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case APPLICATION_FORM_PACKAGES: {
                    const servicesList = additionalData?.[APPLICATION_FORM_SERVICES]?.data;
                    const servicesAttributesList = additionalData?.[APPLICATION_FORM_PACKAGE_SERVICE_ATTRIBUTES]?.data;
                    const servicesPlansList = additionalData?.[APPLICATION_FORM_PACKAGE_PLANS]?.data;

                    const check = servicesList && servicesAttributesList && servicesPlansList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'serviceAttributes') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesAttributesList?.map(
                                                (item: { id: string; name: string }) => ({
                                                    value: item.id,
                                                    name: item.name,
                                                }),
                                            ),
                                        },
                                    };
                                }
                                if (dataModel.name === 'service') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }
                                if (dataModel.name === 'plans') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesPlansList?.map((item: { id: string; title: string }) => ({
                                                value: item.id,
                                                name: item.title,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case APPLICATION_FORM_SERVICES: {
                    const parametersList = additionalData?.[APPLICATION_FORM_PARAMETERS]?.data;

                    const check = parametersList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'parameters') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: parametersList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case APPLICATION_FORM_QUESTIONS: {
                    const servicesList = additionalData?.[APPLICATION_FORM_SERVICES]?.data;

                    const check = servicesList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'service') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case SERVICE_PROCESS_DETAILS:
                case SERVICE_PROCESS_STEPS: {
                    const servicesList = additionalData?.[APPLICATION_FORM_SERVICES]?.data;

                    const check = servicesList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'service') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case SERVICE_PROCESS_SUBTITLE: {
                    const processList = additionalData?.[SERVICE_PROCESS_DETAILS]?.data?.results;

                    const check = processList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'title') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: processList?.map((item: { id: string; heading: string }) => ({
                                                value: item.id,
                                                name: item.heading,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case COMMISSION_LIST_ENTITY: {
                    setFormTemplate((formData: any) =>
                        formData.map((dataModel: any) => {
                            if (dataModel.name === 'commissionType') {
                                return {
                                    ...dataModel,
                                    readonly: !isCreateMode,
                                };
                            }

                            return dataModel;
                        }),
                    );
                    break;
                }

                case DEFECT_TYPES_ENTITY: {
                    const subCategoryOptions = additionalData?.[SUBCATEGORIES_ENTITY]?.data?.results;

                    const check = subCategoryOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'subcategory') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: subCategoryOptions.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }
                case LOCATIONS_ENTITY:
                case ASSETS_ENTITY: {
                    const locationTypesOptions = additionalData?.[LOCATION_TYPES_ENTITY]?.data?.results;

                    const check = locationTypesOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'locationType') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: locationTypesOptions.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case COMPLAINTS_ENTITY: {
                    const defectTypeOptions = additionalData?.[DEFECT_TYPES_ENTITY]?.data?.results;

                    const check = defectTypeOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'defectType') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: defectTypeOptions.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case SUBCATEGORIES_ENTITY: {
                    const categoriesOptions = additionalData?.[CATEGORIES_ENTITY]?.data?.results;

                    const check = categoriesOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'category') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: categoriesOptions.map(
                                                (item: { id: string; name: string; asset: any }) => ({
                                                    value: item.id,
                                                    name: `${item.name} - ${item.asset?.name}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case GARAGE_COMMISSION_TYPE: {
                    const branchNameOptions = additionalData?.[COMMISSION_TYPE]?.data;
                    const commissionListOptions = additionalData?.[COMMISSION_LIST_ENTITY]?.data?.results;

                    const check = branchNameOptions && commissionListOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'garageName') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: branchNameOptions.map(
                                                (item: { id: string; branchName: string }) => ({
                                                    value: item.id,
                                                    name: `${item.branchName}`,
                                                }),
                                            ),
                                        },
                                    };
                                }
                                if (dataModel.name === 'commission') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: commissionListOptions?.map(
                                                (item: { id: string; commissionType: string }) => ({
                                                    value: item.id,
                                                    name: `${item.commissionType}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case CATEGORIES_ENTITY: {
                    const assetsOptions = additionalData?.[ASSETS_ENTITY]?.data?.results;
                    const managersOptions = additionalData?.[ALL_MANAGERS_ENTITY]?.data?.results;

                    const check = assetsOptions && managersOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'asset') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: assetsOptions.map(
                                                (item: { id: string; name: string; locationType: any }) => ({
                                                    value: item?.id,
                                                    name: `${item.name} - ${item?.locationType.name}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                if (dataModel.name === 'manager') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: managersOptions.map((item: { id: string; name: string }) => ({
                                                value: item?.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case MANAGER_TECHNICIAN_ENTITY: {
                    let allTechnicianOptions = additionalData?.[ALL_TECHNICIAN_ENTITY]?.data?.results;
                    const managersOptions = additionalData?.[ALL_MANAGERS_ENTITY]?.data?.results;

                    if (incomingFormData?.results?.technicians.length && allTechnicianOptions?.length) {
                        allTechnicianOptions = [...incomingFormData.results.technicians, ...allTechnicianOptions];
                    }

                    const check = allTechnicianOptions && managersOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'technicians') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel?.fieldType,
                                            options: allTechnicianOptions.map(
                                                (option: { id: string; name: string; mobile: string }) => ({
                                                    value: option.id,
                                                    name: `${option.name}-${option.mobile}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                if (dataModel.name === 'manager') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: managersOptions.map((item: { id: string; name: string }) => ({
                                                value: item?.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }
            }
        };

        updateFormTemplate();
    }, [additionalData, entityName, isCreateMode, incomingFormData]);

    /**
     * this function fetches data from API for any specific course/roles etc and sets that as the form field values. This function is not called while creating a new course/role.
     * @param coursesWorkshopResponseId
     */
    const getApiData = async () => {
        setLoading(true);

        let entityData: any = {};

        if (entityName === HEADER_INFO) {
            entityData = await callApi(HEADER_DATA, 'GET', null, { heroName: props.entityId });
            entityData.data.data.name = props.entityId;
        } else {
            entityData = await callApi(`${props.entityName}/${props.entityId}`, 'GET');
        }

        if (!isCreateMode && entityName === USERS) {
            const formTemplateData = formTemplate.filter((item: any) => item.name !== 'password');
            setFormTemplate(formTemplateData);
        }

        if (entityData?.status && entityData?.data) {
            setIncomingFormData(entityData?.data?.data);
            const data = getIncomingPayload(props.entityName, entityData?.data?.data);
            const defaultObj: any = {};

            for (const key in props.formDefaultPayload) {
                if (key === 'startDate' || key === 'endDate') {
                    defaultObj[key] = data[key] && moment(data[key]);
                } else {
                    defaultObj[key] = data[key] ?? [];
                }
            }

            if (entityName === PRODUCTS && !isCreateMode) {
                const apiData = entityData?.data?.data;
                if (apiData.gallery) {
                    apiData.gallerySpatialRenderImages.forEach((nthImage: any, index: number) => {
                        for (const x of payloadUrlName) {
                            defaultObj[`galleryImage${index + 1}--${x}`] = [{ url: nthImage.urls[0][x], uid: '' }];
                        }
                    });
                }

                defaultObj.gallery = apiData.gallery === false ? 0 : apiData.gallerySpatialRenderImages.length;
            }

            if (entityName === STORIES && !isCreateMode) {
                entityData.data.data.publishDate = formatDate(entityData.data.data.publishDate);
            }

            if (!isCreateMode && entityName === USERS) {
                const formTemplateData = formTemplate.filter((item: any) => item.name !== 'password');
                setFormTemplate(formTemplateData);
            }

            if (!isCreateMode && entityName === REFERRAL_COUPONS_ENTITY) {
                const formTemplateData: any = formTemplate.map((item: any) => {
                    if (item.name === 'couponTarget') {
                        return { ...item, readonly: true };
                    } else {
                        return item;
                    }
                });
                setFormTemplate(formTemplateData);
            }

            if (!isCreateMode && entityName === GARAGE_COMMISSION_TYPE) {
                const formTemplateData: any = formTemplate.map((item: any) => {
                    if (item.name === 'garageName') {
                        return { ...item, readonly: true };
                    } else {
                        return item;
                    }
                });
                setFormTemplate(formTemplateData);
            }

            if (entityName === VARIANTS_ENTITY) {
                form.setFieldsValue(data);
            } else {
                form.setFieldsValue(defaultObj);
            }
            setLoading(false);

            return defaultObj;
        }
    };

    const changeSpatialRender = async (value: boolean) => {
        const updateSpecialImages = (item: any) =>
            item.name === 'specialImages' ? { ...item, isAccordian: Boolean(value) } : item;
        if (entityName === PRODUCTS && isCreateMode) {
            const formTemplateData = formTemplate.map(updateSpecialImages);
            setFormTemplate(formTemplateData);
        } else {
            const formTemplateData = formTemplate.map(updateSpecialImages);
            setFormTemplate(formTemplateData);
            const res = await getApiData();
            res.isSpatialRender = value;
            form.setFieldsValue(res);
        }
    };

    useEffect(() => {
        const validEntityId = props.entityId || props.entityId === '';
        if (!props.isCreateMode && validEntityId) {
            getApiData();
        }
    }, [props.isCreateMode, props.entityId]);

    useEffect(() => {
        (async () => {
            if (entityName === PRODUCTS && !isCreateMode) {
                const res = await getApiData();
                changeSpatialRender(res.isSpatialRender);
            }
        })();
    }, []);

    /** To update the datamodel and the selected form values once both additional data and the specific data for this form have been fetched */
    useEffect(() => {
        if (entityName === ORDER_LIST_ENTITY) {
            const availableDeliveryPartners = additionalData?.[AVAILABLE_DELIVERY_PARTNERS]?.data?.results;

            if (availableDeliveryPartners) {
                updateDatamodel({
                    setFormTemplate,
                    incomingFormData,
                    availableDeliveryPartners,
                    entityId: props.entityId as string,
                });

                updateFormFields({ incomingFormData, form });
            } else {
                if (incomingFormData) {
                    const endPointsForDropdownData = [
                        {
                            name: AVAILABLE_DELIVERY_PARTNERS,
                            getAllResponse: true,
                            queryParams: { storeId: incomingFormData?.store?.id },
                        },
                    ];
                    setAdditionalEntityName(endPointsForDropdownData);
                }
            }
        }
    }, [additionalData, entityName, form, incomingFormData, props.entityId]);

    const submitForm = (rawPayload: any) => {
        /**
         * the payloadProcessor function returns the payload in the format which can be sent in the request. If payloadProcessor function is not passed, then the reduce method is used to process the payload as default
         */

        const payload = props.payloadProcessor
            ? props.payloadProcessor(rawPayload, additionalData, incomingFormData)
            : getOutgoingPayload(
                  entityName,
                  rawPayload,
                  additionalData,
                  extraPayloadData,
                  incomingFormData,
                  nestedEntityName,
                  isCreateMode,
              );

        if (props.isCreateMode) {
            createEntity(payload, props.entityName);
        } else {
            updateEntity(payload);
        }
    };

    const createEntity = async (payload: any, entityName: string) => {
        setLoading(true);

        try {
            const res: any = await callApi(entityName, 'POST', payload);

            if (res?.status) {
                toastSuccess(`${props.headerName} created successfully!`);
                props.onSuccessfulUpdate?.('create');
            } else {
                toastError(
                    `Oops, an error occured! ${props.headerName} creation failed. ${res?.data?.message || failedMsg}`,
                );
            }
        } catch (err) {
            toastError(
                `Oops, an error occured! ${props.headerName} creation failed. Please contact development support team`,
            );
        }
        setLoading(false);
    };

    const updateEntity = async (payload: any) => {
        setLoading(true);

        try {
            const url = props.nestedEntityName || `${props.entityName}/${props.entityId}`;

            const res: any = await callApi(url, updateRequestType, payload);

            if (res?.status) {
                toastSuccess(`${props.formHeader} updated successfully!`);
                props.entityId && props.closeTab?.(props.entityId, true);
            } else {
                toastError(
                    `Oops, an error occured! ${props.formHeader} updation failed. ${res?.data?.message || failedMsg}`,
                );
            }
        } catch (err) {
            toastError(
                `Oops, an error occured! ${props.formHeader} updation failed. Please contact development support team`,
            );
        }

        setLoading(false);
    };

    const onPublish = () => {
        statusRef.current = 'Published';
        form.submit();
    };

    const onUpdate = () => {
        form.submit();
    };

    const onDelete = async () => {
        setLoading(true);

        try {
            const res: any = await callApi(`${props.entityName}/${props.entityId}`, 'DELETE');

            if (res?.status) {
                toastSuccess(`${props.formHeader} deleted successfully!`);
                props.entityId && props.closeTab?.(props.entityId, true);
            } else {
                toastError(
                    `Oops, an error occured! ${props.formHeader} deletion failed. ${res?.data.message || failedMsg}`,
                );
            }
        } catch (err) {
            toastError(
                `Oops, an error occured! ${props.formHeader} deletion failed. Please contact development support team`,
            );
        }

        setLoading(false);
    };

    function getFormElement(formTemplate: InputInterface, i: number) {
        switch (formTemplate?.fieldType?.name) {
            case 'checkboxGroup': {
                return <CheckboxGroup formInput={formTemplate} index={i} form={form} />;
            }
            case 'email': {
                return <Email formInput={formTemplate} index={i} />;
            }
            case 'pinCode': {
                return <PinCode formInput={formTemplate} index={i} />;
            }
            case 'number': {
                return <NumberInput formInput={formTemplate} index={i} />;
            }
            case 'select': {
                return (
                    <SelectInput
                        formInput={formTemplate}
                        setFormData={updateRequired && setFormTemplate}
                        form={form}
                        index={i}
                        isCreateMode={isCreateMode}
                        setIsAdmin={setIsAdmin}
                        setIsSpatialRender={changeSpatialRender}
                    />
                );
            }
            case 'table': {
                return (
                    <TableInput
                        value={defaultFormData[formTemplate.name]}
                        name={formTemplate.name}
                        formTemplate={formTemplate}
                        form={form}
                        index={i}
                        entityId={props.entityId}
                    />
                );
            }
            case 'dynamicSelect': {
                return (
                    <DynamicSelectInput
                        formInput={formTemplate}
                        setFormData={updateRequired && setFormTemplate}
                        form={form}
                        index={i}
                        isCreateMode={isCreateMode}
                    />
                );
            }
            case 'multiSelect': {
                return <MultiSelectInput formInput={formTemplate} form={form} index={i} isCreateMode={isCreateMode} />;
            }
            case 'optionalSingleSelect': {
                return <OptionalSelectInput formInput={formTemplate} index={i} />;
            }
            case 'timeSlot': {
                return <TimeSlotInput formInput={formTemplate} index={i} />;
            }
            case 'multipleTextInput': {
                return (
                    <MultipleTextInput
                        formInput={formTemplate}
                        form={form}
                        entityName={props.nestedEntityName}
                        formHeader={props.formHeader}
                        setLoading={setLoading}
                        index={i}
                    />
                );
            }
            case 'multiImageUpload': {
                return (
                    <MultipleImageUpload formInput={formTemplate} form={form} index={i} isCreateMode={isCreateMode} />
                );
            }

            case 'specialMultiImageUpload': {
                return (
                    <MultiSpatialImageUpload
                        formInput={formTemplate}
                        form={form}
                        index={i}
                        isCreateMode={isCreateMode}
                    />
                );
            }

            case 'galleryImageUpload': {
                return (
                    <GalleryImageUpload formInput={formTemplate} form={form} index={i} isCreateMode={isCreateMode} />
                );
            }

            case 'textEditor': {
                return <TextEditor formInput={formTemplate} index={i} />;
            }
            case 'multipleTwinInput': {
                return <MultipleTwinInput formInput={formTemplate} index={i} />;
            }
            case 'drawerForm': {
                return (
                    <DrawerInput
                        entityId={props.entityId}
                        formInput={formTemplate}
                        form={form}
                        onSuccessfulUpdate={props.onSuccessfulUpdate}
                        isCreateMode={isCreateMode}
                    />
                );
            }
            case 'file':
            case 'image':
            case 'video': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }

            case 'documentUpload': {
                return <DocumentFileInput formInput={formTemplate} index={i} form={form} />;
            }

            case 'documitraFile':
            case 'documitraImage':
            case 'documitraVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }

            case 'uploadFile':
            case 'uploadImage':
            case 'uploadVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }
            case 'photomitraFile':
            case 'photomitraImage':
            case 'photomitraVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }
            case 'date': {
                return <DateInput formInput={formTemplate} index={i} />;
            }

            case 'textarea': {
                return <TextArea formInput={formTemplate} index={i} />;
            }
            case 'permissions': {
                return !isAdmin ? <UserRolePermission formTemplate={formTemplate} index={i} form={form} /> : null;
            }
            default: {
                return <TextInput formInput={formTemplate} index={i} />;
            }
        }
    }
    const allAccordions = getAllAccordions(formTemplate, getFormElement);

    return (
        <Spin spinning={loading}>
            <div className={'abstract-form'}>
                <div className='container1'>
                    <div className='form-container'>
                        <Form
                            form={form}
                            scrollToFirstError={true}
                            initialValues={defaultFormData}
                            onFinish={submitForm}>
                            {allAccordions}
                        </Form>
                    </div>
                </div>
                <div className='btn-container'>
                    <div className='publish-btn-container'>
                        <div className='publish-btn'>
                            {!props.isCreateMode && (
                                <Button type='primary' disabled={props.disabledButton?.update} onClick={onUpdate}>
                                    Update
                                </Button>
                            )}
                        </div>
                        <div className='publish-btn'>
                            {!props.isCreateMode && (
                                <Button type='primary' disabled={props.disabledButton?.delete} onClick={onDelete}>
                                    Delete
                                </Button>
                            )}

                            {props.isCreateMode && (
                                <Button type='primary' onClick={onPublish}>
                                    Create
                                </Button>
                            )}
                        </div>
                        <div className='publish-btn'>
                            <Button
                                type='primary'
                                disabled={props.disabledButton?.cancel}
                                onClick={() => {
                                    props.closeTab?.(props.entityId || 'a', false);
                                }}>
                                Cancel
                            </Button>
                        </div>
                        {additionalButtons?.map((button) => {
                            const { getOnClickHandler, text, key, isDisabled } = button;
                            return (
                                <Button
                                    type='primary'
                                    onClick={getOnClickHandler({
                                        entityId: props.entityId,
                                        setLoading,
                                        onRefresh: getApiData,
                                    })}
                                    key={key}
                                    disabled={isDisabled(incomingFormData)}>
                                    {text}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

AbstractForm.defaultProps = {
    isCreateMode: true,
    entityId: null,
    formDefaultPayload: {},
    hidePreview: false,
};

export default AbstractForm;
